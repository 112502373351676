import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <>
      <section
        className="download-section pt-100"
        style={{
          background:
            "url('assets/img/monaccode-bg-player.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Be data driven and optimize with cloud
                </h2>
                <p className="lead">
                  We have a strategy to innovate with cloud platforms to re-energize your products and services for everything, for everyone and everywhere.
                </p>

                {/* <div className="download-btn">
                  <Link to="/" className="btn google-play-btn mr-3">
                    <span className="ti-android"></span> Google Play
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-4">
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
