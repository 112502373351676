import React, { useEffect, useCallback } from "react";
import AppRoutes from "./routes/Routes";
import { useLocation } from "react-router";
import trackPathForAnalytics from "./TrackPageForAnalytics"

const App = () => {
  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
      trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
  }, [pathname, search]);

  useEffect(() => {
      analytics();
  }, [analytics]);

  return (
    <div>
      <AppRoutes />
    </div>
  );
};

export default App;
