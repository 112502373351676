import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>We are focused on the value creation process</h2>
                <p className="lead">
                We base our operation on managing multicultural teams, professionals focused on creation and innovation, a new generation of talented people building new things that are changing the world.
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-lock text-white"></span>
                </div>
                <h5>Product Discovery and Innovation</h5>
                <p>We are <a href="https://www.interaction-design.org/" target="blank">interaction design foundation</a> certify and <a href="https://cloud.google.com/innovators" target="blank">google cloud innovator</a> member</p>

              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h5>Brand eXperience</h5>
                <p>
                  Clients who have transformed their ideas into profitable products, brands, and businesses
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-vector text-white"></span>
                </div>
                <h5>APIfication<br/>Don’t worry be API</h5>
                <p>
                  Digital Transformation, Web 3.0, Metaverse, Blockchain, Machine Learning, Cognitive Recognition, and AI are concepts in the universe of technology.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
