import React from "react";

export default function FeatureImgThree() {
  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="download-img">
                <img
                  src="assets/img/monaccode-technology-services.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2>Agile Process with Best Services</h2>
                <p>
                  We facilitate the integration, implementation, standardization of processes and synchronization of technologies.
                  We allow a design and consolidation of data end to end and empower digital product operations.
                </p>

                <div className="feature-content-wrap">
                  <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active h6"
                        href="#tab6-1"
                        data-toggle="tab"
                      >
                        <span className="ti-palette"></span>
                        UX/UI
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-2"
                        data-toggle="tab"
                      >
                        <span className="ti-vector"></span>
                        Development
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-3"
                        data-toggle="tab"
                      >
                        <span className="ti-mobile"></span>
                        Mobile
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-4"
                        data-toggle="tab"
                      >
                        <span className="ti-cloud"></span>
                        Cloud
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-1">
                      <ul className="list-unstyled">
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">We base our design processes on data analysis from multiple approaches. </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                              We compile information that allows us to understand businesses, customers, user behaviors, and technologies among others, to generate prototypes, designs, and flows of processes and efficient interactions.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                We transform the user experience (UX) to generate a high- impact, closer, and more real interaction between companies, brands, customers, and their environment
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-pane" id="tab6-2">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-heart-broken rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Quality and CI/CD</h5>
                        </div>
                        <p>
                        We base our development projects firstly on ensuring the quality of our solutions. 
                        We provide an efficient tech orchestration to ensure what the product or requirement really needs, a smart solution, 
                        combining and applying robust tech stacks with a cloud architecture that allows scalability and sustainability 
                        under a continuous integration and delivery (CI/CD) scheme.
                        </p>
                      </div>
                      <div className="single-feature mb-4">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-alarm-clock rounded mr-3 icon icon-color-2"></span>
                          <h5 className="mb-0">Acelerate the development process</h5>
                        </div>
                        <p>
                        In our work, we apply agile methodologies providing a development dynamic integrating client-developer 
                        relationship as a value creation formula complementing our software development life-cycle process. 
                        Our engineers have the know-how about how to accelerate the development process of any digital product, 
                        with the experience, endorsement, and certification of key technologies in the market, 
                        which means that we lead to the creation of solid solutions for any industry.
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-3">
                      <div className="row">
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-medall rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Standardization</h5>
                          </div>
                          <p>
                          Acceleration and presence are our recommendations before carrying out functionalities on mobile devices. 
                          In a fast-paced and changing environment, standardization plays an important role in generating solutions. 
                          This is why we enhance your ideas by implementing frameworks that cover the most demanded platforms (Android, iOS) 
                          so that your product generates value efficiently about cost benefit relation.
                          </p>
                        </div>
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-tablet rounded mr-3 icon icon-color-3"></span>
                            <h5 className="mb-0">Multiplatform</h5>
                          </div>
                          <p>
                          There are large communities of developers creating more and more multiplatform environments, 
                          generating access for any type of business, service, and or product to millions of users in real time, 
                          which is why presence is the key, but the platform became unified, the greats of technology aim 
                          to ensure that access to functionalities is UNIVERSAL and that the experience is what makes the difference.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-4">
                      <p>
                      We are partners of the key players in the industry, AWS, GCP, and Azure. We rely on our partner and allied benefits to accelerate and empower your ideas. 
                      <br/><br/>
                      Our architects design solutions with the vision of "unattended solutions" for SMEs and Corporations, understanding 
                      each technological ecosystem and implementing event-driven architecture, and microservices, among others.
                      <br/><br/>
                      About Cloud first, our approach is integration. Integration of the tech ecosystem that enhances the business. We lead the digital transformation process from scratch, 
                      implementing standard products or services and putting technology to work for your business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
