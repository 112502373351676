import React from "react";

export default function Contact({ bgColor }) {
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Contact With Us</h2>
                <p>
                "Sometimes when you innovate, you make mistakes. It's better to admit them quickly and keep improving your other innovations."
                <br/>
                <span className="font-italic">Steve Jobs</span>
                </p>
              </div>
              <div className="footer-address">
                <h6>
                  <strong>Head Office</strong>
                </h6>
                <p>Miami, Florida, United States</p>
                <ul>
                  <li>
                    <span>Phone: +1 (786) 334-1058</span>
                  </li>
                  <li>
                    <span>
                      Email:
                      <a href="mailto:hello@monaccode.dev">
                        &nbsp;hello@monaccode.dev
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              
                <h5>Let's keep in touch</h5>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <a href="https://outlook.office365.com/owa/calendar/Letskeepintouch@monaccode.com/bookings/" target="blank" className="btn google-play-btn">
                      Schedule a Meeting
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
