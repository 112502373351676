import React from "react";

export default function Team({ hasTitle, bgColor }) {
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                {hasTitle ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Lovely Team
                  </span>
                ) : (
                  ""
                )}
                <h2>Our Team Members</h2>
                <p className="lead">
                We transform the user experience (UX) to generate a high- impact, closer, and more real interaction between companies, brands, customers, and their environment.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-development-mario-molins.jpg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Mario Molins</h5>
                  <h6>Sales Manager</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/mario-molins-86532343" target="blank">
                        <span className="ti-linkedin"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-development-wander-delgado.jpg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Wander Adrian Delgado</h5>
                  <h6>Business and Product Builder</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/wander-adrian-delgado-b4543a5" target="blank">
                        <span className="ti-linkedin"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-development-pedro-sixto-gonzalez.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Pedro Sixto González</h5>
                  <h6>Executive Creative</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                  <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/pedrosgonzalez" target="blank">
                        <span className="ti-linkedin"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-development-alejandro-diaz.jpg"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Alejandro Diaz</h5>
                  <h6>Design Interaction Director</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                  <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/alejandro-diaz-4985886b" target="blank">
                        <span className="ti-linkedin"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center justify-content-center mt-4">
          <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/team-development-juan-carlos-romero.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Juan Carlos Romero</h5>
                  <h6>Advisor Cloud/Software Architect</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/juan-carlos-romero-fulfaro" target="blank">
                        <span className="ti-linkedin"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://twitter.com/fulfarodev" target="blank">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://g.dev/fulfaro" target="blank">
                        <span className="ti-google"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://learn.microsoft.com/en-us/users/fulfaro" target="blank">
                        <span className="ti-microsoft"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
}
