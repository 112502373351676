import React from "react";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>
                  We provide the necessary technology to transform and drive your ideas into digital products
                </h2>

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-1.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      <span className="font-weight-bold">Turning ideas into digital products.</span>
                      <br/>
                      #ProductDesign #MVP #PoC #Research #Prototype #Web&MobileDesign #ServiceDesign #Persons
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-3.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      <span className="font-weight-bold">Red, Green, Blue – Initial Test, Code, and Refactor.</span>
                      <br/>
                      #StartUp #Enterprise #OpenSource #Licensing #Cloud #Arquitecture #PoC #Agile #Scrum #MVP #TDD #Frameworks #Certifications
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-4.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      <span className="font-weight-bold">Designing solutions “Mobile First”.</span>
                      <br/>
                      #Android #iOS #Native #Hibrid #ReactNative #Expo #MAUI #Firebase
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-2.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      <span className="font-weight-bold">Cloud First / Cloud Native</span>
                      <br/>
                      #CloudFirst #Architecture #DevOps #SaaS #FaaS #PaaS #BaaS #IaaS #APIfication #Serverless #NoSQL
                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/monaccode-technology-team.png"
                  alt="Monaccode Technology Team"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
