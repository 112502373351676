import React from "react";
import OwlCarousel from "react-owl-carousel";

export default function BrandCarousel({ hasBg }) {
  const options = {
    autoplay: true,
    loop: true,
    margin: 15,
    dots: true,
    slidetransition: "linear",
    autoplayTimeout: 4500,
    autoplayHoverPause: true,
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 2,
      },
      500: {
        items: 3,
      },
      600: {
        items: 4,
      },
      800: {
        items: 5,
      },
      1200: {
        items: 6,
      },
    },
  };

  return (
    <>
      <section
        className={`client-section ptb-100 ${hasBg ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Our Technology Skills
                  </span>
                ) : (
                  ""
                )}
                <h2>Our Technology Skills</h2>
                <p className="lead">
                  We are a tech company that builds software, solutions, and digital products allowing companies to accelerate their growth. 
                  <br/><br/>
                  We are focused on the value-creation process, understanding what's the companies' real needs for their business, customers, and environment, designing efficient solutions, and generating innovation to make a difference in a highly competitive global environment.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12">
              <OwlCarousel
                className="owl-carousel owl-theme clients-carousel dot-indicator"
                {...options}
              >
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-javascript.png"
                    alt="JavaScript"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-typescript.png"
                    alt="TypeScript"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-java.png"
                    alt="Java"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-microsoft.net.png"
                    alt="Microsoft .Net"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-reactjs.png"
                    alt="ReactJS"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-react-native.png"
                    alt="React Native"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-cloud-amazon-aws.jpg"
                    alt="Amazon AWS"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-google-cloud.png"
                    alt="Google Cloud Platform"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-cloud-microsoft-azure.png"
                    alt="Microsoft Azure"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-cloud-terraform.png"
                    alt="Terraform"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-tableau.png"
                    alt="Tableau"
                    className="client-img"
                  />
                </div>
                <div className="item single-client">
                  <img
                    src="assets/img/technology-development-powerbi.png"
                    alt="Power BI"
                    className="client-img"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
