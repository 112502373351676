import React from "react";
export default function HeroSeven() {
  return (
    <>
      <section
        className="hero-section hero-section-special ptb-100"
        style={{
          background:
            "url('assets/img/monaccode-background-buenos-aires.svg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6">
              <div className="position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                <h3 className="text-white">We are passionate<br/>about Innovation</h3>
                <h1 className="text-white big-text mb-0">
                  <span>BE IN THE</span>CLOUD
                </h1>
                <p className="lead font-weight-bold">
                  Turning ideas into products<br/>DISRUPTIVE IDEAS that make the difference
                </p>
                <a href="https://outlook.office365.com/owa/calendar/Letskeepintouch@monaccode.com/bookings/" target="blank" className="btn google-play-btn">
                  Let's keep in touch
                </a>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
